export const pingAuth = async () => {
  try {
    const response = await fetch('/user/pingauth', {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' }
    })
    if (response.ok) {
      return await response.json()
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}
