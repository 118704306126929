/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { pingAuth } from '../utils/authUtils.jsx'
import { useLoader } from '../hooks/useLoader'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [requires2FA, setRequires2FA] = useState(false)
  const [twoFactorTempData, setTwoFactorTempData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { showLoader, hideLoader } = useLoader()

  useEffect(() => {
    async function fetchUser () {
      console.log('pinging auth')
      showLoader()
      try {
        const user = await pingAuth()
        if (user) {
          setUser(user)
        }
      } finally {
        hideLoader()
      }
    }
    fetchUser()
  }, [])

  useEffect(() => {
    console.log('User state changed:', user)
  }, [user])

  const loginAction = async (userData) => {
    const loginBody = {
      email: userData.email,
      password: userData.password,
      rememberMe: userData.rememberme
    }

    try {
      const response = await fetch('/identity/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginBody)
      })

      if (response.ok) {
        const result = await response.json()
        if (result.success) {
          const user = await pingAuth()
          setUser(user)
          setIsLoading(false)
          return result
        } else if (result.requires2FA) {
          setRequires2FA(true)
          setTwoFactorTempData(userData)
          setIsLoading(false)
          return result
        } else if (result.lockout) {
          setIsLoading(false)
          return result
        }
      } else if (response.status === 401) {
        return { success: false }
      } else {
        console.error(response)
        return { success: false }
      }
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      return { success: false }
    }
  }

  const verifyTwoFactorAction = async (twoFactorCodeData) => {
    const twoFactorBody = {
      username: twoFactorTempData.email,
      password: twoFactorTempData.password,
      code: twoFactorCodeData.twoFactorCode,
      rememberme: twoFactorTempData.rememberme,
      remembermachine: twoFactorCodeData.remembermachine
    }

    try {
      const response = await fetch('/identity/verify-2fa', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(twoFactorBody)
      })

      if (response.ok) {
        const result = await response.json()
        if (result.success) {
          const user = await pingAuth()
          setUser(user)
          setRequires2FA(false)
          setTwoFactorTempData(null)
          ReactGA.set({
            user_id: user.userId,
            user_role: user.roles[0]
          })
          return result
        }
      } else {
        console.error(response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const logout = async () => {
    try {
      const response = await fetch('/identity/logout', {
        method: 'POST',
        credentials: 'include'
      })

      if (response.ok) {
        setUser(null)
        localStorage.clear()
      } else {
        console.error('Failed to log out.')
      }
    } catch (error) {
      console.error('Error logging out:', error)
    }
  }

  return (
    <AuthContext.Provider value={{ user, loginAction, logout, requires2FA, verifyTwoFactorAction, isLoading, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
