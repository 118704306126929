import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import axios from 'axios'
import FeedbackIcon from '@mui/icons-material/Feedback'
import IconButton from '@mui/material/IconButton'
import { Store } from 'react-notifications-component'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))

const FeedbackButton = () => {
  const [open, setOpen] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    // Check if the tooltip has been shown in this session
    const hasShownTooltip = localStorage.getItem('hasShownTooltip')

    if (!hasShownTooltip) {
      setShowTooltip(true)

      // Set timeout to hide tooltip after 5 seconds
      const timer = setTimeout(() => {
        setShowTooltip(false)
        localStorage.setItem('hasShownTooltip', 'true')
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [])

  const handleCloseTooltip = () => {
    setShowTooltip(false)
    localStorage.setItem('hasShownTooltip', 'true') // Ensure it doesn't show again
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async () => {
    try {
      await axios.post('/Feedback', { feedback })
      setOpen(false)
    } catch (error) {
      console.error('Error submitting feedback:', error)
      setOpen(false)
    }
    Store.addNotification({
      title: '✓',
      message: 'Tak for din feedback',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })
  }

  return (
    <>
      {showTooltip
        ? (
          <HtmlTooltip
            open={showTooltip}
            title={
              <React.Fragment>
                {'Hvis du har problemer eller ideér til forbedringer, kan du skrive dem til os her'}
                <div style={{ marginTop: '8px', textAlign: 'right' }}>
                  <Button
                    color="primary"
                    size="small"
                    onClick={handleCloseTooltip}
                  >
                    Luk
                  </Button>
                </div>
              </React.Fragment>
            }
          >
            <IconButton onClick={handleClickOpen}>
              <FeedbackIcon variant="contained" color="primary" />
            </IconButton>
          </HtmlTooltip>
        )
        : (
          <IconButton onClick={handleClickOpen}>
            <FeedbackIcon variant="contained" color="primary" />
          </IconButton>
        )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Har du tips til forbedringer? Send dem venligst til os nedenfor</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Din feedback"
            type="text"
            fullWidth
            multiline
            rows={6}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Afbryd
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FeedbackButton
