/* eslint-disable react/prop-types */
import React from 'react'
import './Loader.css'

export const Loader = ({ message }) => {
  return (
    <div className="loader">
      <span className="spinner"></span>
      <span className="loader-message">{message}</span>
    </div>
  )
}
