import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

const RouteChangeTracker = () => {
  const location = useLocation()

  useEffect(() => {
    // Correct way to send a page view in react-ga4
    ReactGA.send({ hitType: 'pageview', page: location.pathname })
  }, [location])

  return <div></div>
}

export default RouteChangeTracker
