/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AppProvider } from '@toolpad/core/AppProvider'
import { DashboardLayout } from '@toolpad/core/DashboardLayout'
import { useNavigate } from 'react-router-dom'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { usePrivacyMode } from '../../contexts/PrivacyModeContext'
import NAVIGATION_ADMIN from './NavigationAdmin'
import NAVIGATION_PORTFOLIO_MANAGER from './NavigationPortfolioManager'
import NAVIGATION_RELATIONSHIP_MANAGER from './NavigationRelationshipManager'
import NAVIGATION_DEFAULT from './NavigationDefault'
import NAVIGATION_UNAUTHENTICATED from './NavigationUnauthenticated'
import FeedbackButton from '../ui/Feedback'
import Stack from '@mui/material/Stack'
import { isMobileDevice } from '../../utils/generalUtils'
import { useAuth } from '../../contexts/AuthContext.jsx'

const mermaidTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme'
  },
  colorSchemes: { light: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 1200,
      md: 1200,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    background: {
      default: '#f5f5f5'
    }
  }
})

const mermaidTheme2 = createTheme({
  palette: {
    background: {
      default: '#FFFFFF'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 1200,
      md: 1400,
      lg: 1400,
      xl: 1536,
      dateBar: 600
    }
  }
})

function DashboardLayoutBasic (props) {
  const { children } = props // Add children here
  const [pathname, setPathname] = React.useState('/')
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [session, setSession] = React.useState(null)
  const { user, logout, setUser } = useAuth()
  function ToolbarActions () {
    const { isPrivacyMode, togglePrivacyMode } = usePrivacyMode()

    const handlePrivacyModeToggle = () => {
      togglePrivacyMode()
    }

    return (
      user?.roles && (
        (user.roles[0] === 'Administrator' ||
          user.roles[0] === 'PortfolioManager' ||
          user.roles[0] === 'RelationshipManager'
        )
          ? (
            <Stack direction="row" spacing={1}>
              <FeedbackButton />
              <IconButton
                onClick={handlePrivacyModeToggle}
                title={'Skjul følsom information'}
              >
                {isPrivacyMode ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
              </IconButton>
            </Stack>
          )
          : <FeedbackButton />
      )
    )
  }

  const router = React.useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => {
        setPathname(String(path))
        navigate(String(path)) // Update the URL and navigate
      }
    }
  }, [pathname, navigate])

  // Fetch session data when the component mounts
  useEffect(() => {
    const populateSession = async () => {
      setSession({
        user: {
          name: user?.userName,
          email: user?.userName,
          image: user?.profilePicture,
          role: user?.roles[0]
        }
      })
    }
    populateSession()
  }, [user])

  const authentication = React.useMemo(() => ({
    signIn: async () => {
      setSession({
        user: {
          name: user.name,
          email: user.email,
          image: user.profilePicture,
          role: user?.roles[0]
        }
      })
    },
    signOut: async () => {
      setUser(null)
      navigate('/Login')
      logout()
    }
  }), [])

  const getNavigationConfig = () => {
    if (!session || !session.user || !session.user.role) {
      return NAVIGATION_UNAUTHENTICATED
    }
    switch (session.user.role) {
    case 'Administrator':
      return NAVIGATION_ADMIN
    case 'PortfolioManager':
      return NAVIGATION_PORTFOLIO_MANAGER
    case 'RelationshipManager':
      return NAVIGATION_RELATIONSHIP_MANAGER
    case 'Default':
      return NAVIGATION_DEFAULT
    default:
      return NAVIGATION_DEFAULT
    }
  }

  return (

    <AppProvider
      navigation={getNavigationConfig()}
      router={router}
      theme={mermaidTheme}
      session={session}
      authentication={authentication}
      branding={{
        logo: <img
          src="/favicon.ico"
          alt="Mermaid Portal logo"
          style={{
            transform: 'scale(0.8)',
            transformOrigin: 'center'
          }}
        />,
        title: !isMobileDevice() ? 'Mermaid Portal' : ''
      }}
    >
      <ThemeProvider theme={mermaidTheme2}>
        <DashboardLayout slots={{ toolbarActions: ToolbarActions }}>
          {children}
        </DashboardLayout>
      </ThemeProvider>
    </AppProvider>
  )
}

export default DashboardLayoutBasic
