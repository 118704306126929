import * as React from 'react'
import ListIcon from '@mui/icons-material/List'
import SourceIcon from '@mui/icons-material/Source'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import PeopleIcon from '@mui/icons-material/People'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import AssessmentIcon from '@mui/icons-material/Assessment'
import BarChartIcon from '@mui/icons-material/BarChart'
import GroupIcon from '@mui/icons-material/Group'

const NAVIGATION_PORTFOLIO_MANAGER = [
  {
    kind: 'header',
    title: 'Hjem'
  },
  {
    segment: 'Portfolio',
    title: 'Min portefølje',
    icon: <BarChartIcon />
  },
  {
    segment: 'Transaction',
    title: 'Transaktioner',
    icon: <ListIcon />
  },
  {
    segment: 'Document',
    title: 'Dokumenter',
    icon: <SourceIcon />
  },
  {
    kind: 'divider'
  },
  {
    kind: 'header',
    title: 'Administration'
  },
  {
    segment: 'ClientManage',
    title: 'Kundestyrring',
    icon: <ManageAccountsIcon />,
    children: [
      {
        segment: '',
        title: 'Kunder',
        icon: <GroupIcon />,
        pattern: '/:id'
      },
      {
        segment: 'MissingDoc',
        title: 'Mangler dokument',
        icon: <ManageSearchIcon />
      },
      {
        segment: 'Processes',
        title: 'Processer',
        icon: <AccountTreeIcon />
      }
    ]
  },
  {
    segment: 'UserManage',
    title: 'Portal brugere',
    icon: <PeopleIcon />
  },
  {
    segment: 'Gold',
    title: '007 Gold',
    icon: <ContactPhoneIcon />
  },
  {
    segment: 'GoldReport',
    title: '007 Gold Rapport',
    icon: <AssessmentIcon />
  }
]

export default NAVIGATION_PORTFOLIO_MANAGER
