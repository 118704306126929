/* eslint-disable react/prop-types */
import React, { createContext, useState, useContext } from 'react'
import { Loader } from '../components/Loader'

export const LoaderContext = createContext(undefined)

export const LoaderProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [loaderMessage, setLoaderMessage] = useState(undefined)

  const contextValue = {
    showLoader: (message) => {
      setLoaderMessage(message)
      setIsVisible(true)
    },
    hideLoader: () => {
      setIsVisible(false)
    }
  }

  return (
    <LoaderContext.Provider value={contextValue}>
      {isVisible && <Loader message={loaderMessage} />}
      {children}
    </LoaderContext.Provider>
  )
}

export const useLoader = () => {
  const context = useContext(LoaderContext)

  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider')
  }

  return context
}
