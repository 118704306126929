import * as React from 'react'
import LoginIcon from '@mui/icons-material/Login'

const NAVIGATION_UNAUTHENTICATED = [
  {
    segment: 'Login',
    title: 'Log ind',
    icon: <LoginIcon />
  },
  {
    segment: 'Register',
    title: 'Opret bruger',
    icon: <LoginIcon />
  }
]

export default NAVIGATION_UNAUTHENTICATED
