import { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useAuth } from './../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
  const idleTimeout = 1800000
  const [isIdle, setIdle] = useState(false)
  const { logout, setUser } = useAuth()
  const navigate = useNavigate()

  const handleIdle = () => {
    setIdle(true)
    setUser(null)
    logout()
    navigate('/Login')
  }
  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleIdle,
    debounce: 500
  })
  return {
    isIdle,
    setIdle,
    idleTimer
  }
}
export default useIdleTimeout
