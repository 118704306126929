/* eslint-disable react/prop-types */
import React, { useEffect, useState, Suspense } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { useLoader } from './hooks/useLoader'
import { Loader } from './components/Loader'
import useCheckSession from './hooks/useCheckSession'

const AppRoutes = ({ userRole }) => {
  const [RoutesConfig, setRoutesConfig] = useState([])
  const { showLoader, hideLoader } = useLoader()

  useEffect(() => {
    const loadRoutes = async () => {
      showLoader()
      let routes = []
      switch (userRole) {
      case 'Default':
        routes = (await import('./routes/DefaultAppRoutes')).default
        break
      case 'Administrator':
        routes = (await import('./routes/AdminAppRoutes')).default
        break
      case 'PortfolioManager':
        routes = (await import('./routes/PortfolioManagerAppRoutes')).default
        break
      case 'RelationshipManager':
        routes = (await import('./routes/PortfolioManagerAppRoutes')).default
        break
      default:
        routes = (await import('./routes/BasicAppRoutes')).default
        break
      }
      setRoutesConfig(routes)
      hideLoader()
    }
    loadRoutes()
  }, [userRole])

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {RoutesConfig.map((route, index) => {
          const { element: LazyComponent, requireAuth, roles, ...rest } = route

          return (
            <Route
              key={index}
              {...rest}
              element={
                <ProtectedRoute
                  element={<LazyComponent />}
                  requireAuth={requireAuth}
                  roles={roles}
                  userRole={userRole}
                />
              }
            />
          )
        })}
      </Routes>
    </Suspense>
  )
}

const ProtectedRoute = ({ element, requireAuth, roles, userRole }) => {
  const navigate = useNavigate()
  useCheckSession(requireAuth)

  if (requireAuth && !roles.includes(userRole)) {
    return navigate('/Login')
  }
  return element
}

export default AppRoutes
