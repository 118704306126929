import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { pingAuth } from '../utils/authUtils.jsx'
import { useAuth } from '../contexts/AuthContext.jsx'

const useCheckSession = (requiresAuth) => {
  const navigate = useNavigate()
  const { logout } = useAuth()

  useEffect(() => {
    if (!requiresAuth) {
      return // If no authentication is required, skip the session check
    }
    const checkSession = async () => {
      try {
        const user = await pingAuth()
        if (!user) {
          logout()
          navigate('/Login')
        }
      } catch (error) {
        console.error('Error checking session:', error)
        logout()
        navigate('/Login')
      }
    }

    // Check session on load
    checkSession()

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkSession()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [navigate])
}

export default useCheckSession
