/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState, useEffect } from 'react'

// Create a context
export const LocalStorageContext = createContext()

// Custom hook to use the local storage context
export const useLocalStorage = () => useContext(LocalStorageContext)

// LocalStorageProvider component
export const LocalStorageProvider = ({ children }) => {
  const [data, setData] = useState(null)

  // Function to fetch data from local storage
  const fetchDataFromLocalStorage = () => {
    const storedData = localStorage.getItem('homeData')
    setData(storedData ? JSON.parse(storedData) : null)
  }

  // Function to save data to local storage
  const saveDataToLocalStorage = newData => {
    localStorage.setItem('homeData', JSON.stringify(newData))
    setData(newData)
  }

  const addDataToLocalStorage = newData => {
    const currentData = JSON.parse(localStorage.getItem('homeData')) || {} // Get current data or initialize as empty object
    const updatedData = { ...currentData, ...newData } // Merge new data with existing data
    localStorage.setItem('homeData', JSON.stringify(updatedData)) // Save updated data to local storage
    setData(updatedData) // Update component state
  }

  // Function to clear data from local storage
  const clearDataFromLocalStorage = () => {
    localStorage.removeItem('homeData')
    localStorage.removeItem('homeGetPortfoliosApiData')
    setData(null)
  }

  // Load data from local storage on component mount
  useEffect(() => {
    fetchDataFromLocalStorage()
  }, [])

  return (
    <LocalStorageContext.Provider
      value={{
        data,
        saveDataToLocalStorage,
        addDataToLocalStorage,
        clearDataFromLocalStorage
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  )
}
